import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import { Button, CardActions, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useInfiniteRequests } from '../../api/requests/useRequests'
import { REQUEST_TYPE_MASS_VACATION } from '../../constants/RequestConstants'
import { massVacationManager } from '../../utils/DateUtil'
import { hasPriorityGapsOrZero } from '../../utils/requests'
import HomePageCard from '../common/HomePageCard'

const MassVacationCard = () => {
  const mvManager = massVacationManager()
  const { data, isRefetching } = useInfiniteRequests('ALL', REQUEST_TYPE_MASS_VACATION)

  let navigate = useNavigate()
  const { hasGaps, priorityMessage } = hasPriorityGapsOrZero(data?.pages[0]?.requests)

  const renderAction = () => {
    return (
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          size="small"
          data-cy="viewSched"
          onClick={() => navigate('/team-member/requests/mass_vacation')}
        >
          Mass Vacation
        </Button>
      </CardActions>
    )
  }

  if (mvManager.hideForTM) {
    return <></>
  }

  return (
    <Grid
      item
      xs={12}
      marginX={1}
      marginY={'6px'}
      border={1}
      borderColor={(theme) => theme.palette.secondary.main}
      borderRadius={1}
    >
      <HomePageCard
        title="Mass Vacation"
        icon={<NotificationImportantIcon />}
        isRefetching={isRefetching}
        cardActions={renderAction}
      >
        {mvManager.message}
        {hasGaps ? (
          <Typography color={'primary'} marginTop={1}>
            {priorityMessage}
          </Typography>
        ) : (
          ''
        )}
      </HomePageCard>
    </Grid>
  )
}

export default MassVacationCard
