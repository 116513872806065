/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, CircularProgress, Paper, useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { useAuth } from '@praxis/component-auth'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'
import {
  ERROR_CODES_SHOW_MESSAGE_PN_SETTINGS,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PN_SETTINGS,
  PN_SETTINGS_SUBMIT_ERROR_MESSAGE,
  PN_SETTINGS_SUBMIT_SUCCESS_MESSAGE,
} from '../../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import {
  clearUserPreferencesData,
  getUserPreferences,
  postUserPreferences,
  setUserPreferencesLoading,
} from '../../../store/settings/actionCreator'
import { padEmpIdWithZeros } from '../../../utils/EmployeeId'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import { CALL_IN_CALL_OFF_TEAM_MEMBER, SCHEDULE_TEAM_MEMBER, SCREEN_NAME_SWAP_SHIFT } from '../../../utils/ScreenName'
import CheckBoxTME from '../../FormElementsTME/CheckBoxTME'
import HeaderTitle from '../../Header/HeaderTitle'
import InnerLoader from '../../Loader/InnerLoader'
import CancelPromptDialog from '../CancelPromptDialog'
import ExitPromptDialog from '../ExitPromptDialog'

const PAGE_TITLE = 'My Push Notifications'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  loadingIconContainer: theme.loadingIconContainer,
  AccordionDetailsCls: {
    display: 'block',
  },
  actionControlsContainer: {
    bottom: 60,
    position: 'absolute',
    width: '100%',
    borderTop: '1px solid #999',
    maxWidth: 640,
    textAlign: 'right',
    backgroundColor: '#FFF',
  },
  action: {
    display: 'inline-block',
    padding: '1rem 1rem',
    verticalAlign: 'middle',
  },
  linkCls: {
    color: '#cc0000',
    fontSize: 14,
  },
  disableLinkCls: {
    color: '#999',
    fontSize: 14,
    textDecoration: 'none',
  },
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '600px',
    padding: '20px 30px',
    textAlign: 'center',
  },
})

const PushNotification = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const auth = useAuth()
  const userDetails = useSelector((state) => state.layout.userDetails)
  const locationDetails = useSelector((state) => state.layout.locationDetails)
  // const { classes } = props
  const [showLoader, setLoader] = useState(true)
  const { data: userFeatures } = useUserFeatures()
  const userRoles = [SCHEDULE_TEAM_MEMBER, CALL_IN_CALL_OFF_TEAM_MEMBER, SCREEN_NAME_SWAP_SHIFT]
  const [pnTypesList, setPNTypesList] = useState([])
  const [pnTypesArr, setPNTypesArr] = useState([])
  const userPreferences = useSelector((state) => state.settings.userPreferences)
  // const [noFeatures, setNoFeatures] = useState(true)
  const [userNewSettings, setUserNewSettings] = useState([])
  const [getUserCurrentSettings, setUserCurrentSettings] = useState([])
  const [isModified, setIsModified] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [postReq, setPostReq] = useState([])
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { session } = auth
  const { userInfo } = session
  const [changesArr, setChangesArr] = useState([])
  const [deltaArr, setDeltaArr] = useState({})
  const userPreferencesPostResponse = useSelector((state) => state.settings.userPreferencesPostResponse)
  const userPreferencesPostErrResponse = useSelector((state) => state.settings.userPreferencesPostErrResponse)
  const userPreferencesLoading = useSelector((state) => state.settings.userPreferencesLoading)
  // const userPreferencesError = useSelector((state) => state.settings.userPreferencesError)

  useEffect(() => {
    if (userPreferencesPostResponse) {
      dispatch(showNotificationSuccess(true, PN_SETTINGS_SUBMIT_SUCCESS_MESSAGE))
      setUserCurrentSettings([])
      setUserNewSettings([])
      setPNTypesArr([])
      setPostReq([])
      setChangesArr([])
      setDeltaArr([])
      navigate('/team-member/settings')
    } else if (userPreferencesPostErrResponse) {
      if (
        userPreferencesPostErrResponse.code &&
        userPreferencesPostErrResponse.message &&
        ERROR_CODES_SHOW_MESSAGE_PN_SETTINGS.includes(userPreferencesPostErrResponse.code)
      ) {
        dispatch(showNotificationError(true, userPreferencesPostErrResponse.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            PN_SETTINGS_SUBMIT_ERROR_MESSAGE +
              ' ' +
              formatErrorCode(userPreferencesPostErrResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PN_SETTINGS),
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferencesPostResponse, userPreferencesPostErrResponse])

  useEffect(() => {
    // Anything in here is fired on component mount.
    dispatch(setUserPreferencesLoading('Y'))
    setLoader(true)
    setSubmitClicked(false)

    return () => {
      // Anything in here is fired on component unmount.
      dispatch(clearUserPreferencesData())
      setUserCurrentSettings([])
      setUserNewSettings([])
      setPNTypesArr([])
      setPostReq([])
      setChangesArr([])
      setDeltaArr([])
    }
  }, [])

  useEffect(() => {
    dispatch(setUserPreferencesLoading('Y'))
    dispatch(
      getUserPreferences(
        padEmpIdWithZeros(userInfo?.empid, 10),
        locationDetails.location_id,
        locationDetails.location_type,
      ),
    )
  }, [auth, userDetails, locationDetails])

  useEffect(() => {
    if (Object.keys(userPreferences).length > 0) {
      setLoader(false)
      let userPNPrefs = [...userPreferences.team_member_preference_list]
      let optedArr = findingOptedInItems([...userPNPrefs])
      setUserCurrentSettings([...optedArr])
      setUserNewSettings([...optedArr])
      setPNTypesList(userPNPrefs.filter(checkUserFeature))
    }
  }, [userPreferences])

  /*   useEffect(() => {
    if (userPreferencesLoading === 'N' && userPreferences !== null) {
      setLoader(false)
      if (userPreferences.team_member_preference_list?.length > 0) {
        setNoFeatures(true)
      }
    } else if (userPreferencesLoading === 'N' && userPreferencesError !== null) {
      setLoader(false)
    }
  }, [userPreferencesLoading, userPreferences, userPreferencesError]) */

  const findingOptedInItems = (arr) => {
    let userOptedArr = []
    let arrForPostReq = []
    for (let item of arr) {
      if (item.is_opted && item.sub_types.length === 0) {
        userOptedArr.push(item.type)
        arrForPostReq.push(item)
      } else if (item.sub_types?.length > 0) {
        for (let subItem of item.sub_types) {
          if (subItem.is_opted) {
            userOptedArr.push(subItem.type)
            arrForPostReq.push(item)
          }
        }
      }
    }
    setPostReq(arrForPostReq)
    return userOptedArr
  }

  useEffect(() => {
    let isModified = hasPNSettingsUpdated()
    setIsModified(isModified)
  }, [userNewSettings])

  useEffect(() => {
    if (pnTypesList.length > 0) {
      processPNTypeList()
    }
  }, [pnTypesList])

  const checkUserFeature = (item) => {
    for (let role of userRoles) {
      if ((userFeatures.screenAccess.includes(role) && item.targetAudience.includes(role)) || item.is_mandatory) {
        return item
      }
    }
  }

  const processPNTypeList = () => {
    let pnTypesArrCopy = [...pnTypesList]

    userNewSettings.map((setting) => {
      return pnTypesArrCopy.map((item) => {
        let val = item.is_mandatory || item.type.toLowerCase() === setting.toLowerCase()
        return {
          ...item,
          is_opted: !!val,
          isIndeterminated: false,
          subTypeCheckedCount: 0,
          // eslint-disable-next-line array-callback-return
          sub_types: item.sub_types.map((subItem) => {
            let subVal = subItem.type.toLowerCase() === setting.toLowerCase()
            if (val) {
              return { ...subItem, is_opted: true }
            }
            if (subVal & !val) {
              return { ...subItem, is_opted: true }
            }
          }),
        }
      })
    })

    for (let item of pnTypesArrCopy) {
      for (let subItem of item.sub_types) {
        if (subItem.is_opted) {
          item.subTypeCheckedCount++
        }
        if (item.subTypeCheckedCount === 0) {
          item.is_opted = false
          item.isIndeterminated = false
        }
        if (item.subTypeCheckedCount > 0 && item.subTypeCheckedCount < item.sub_types.length) {
          item.is_opted = false
          item.isIndeterminated = true
        }
        if (item.subTypeCheckedCount === item.sub_types.length) {
          item.is_opted = true
          item.isIndeterminated = false
        }
      }
    }
    setPNTypesArr([...pnTypesArrCopy])
  }

  let tempChangesArr = []

  const handleMainChange = (val, type) => {
    let newPNTypesArr = [...pnTypesArr]

    let tempArr = newPNTypesArr.map((item) => {
      if (item.type === type) {
        // checked/unchecked on the a main item with sub_types
        if (item.sub_types.length > 0) {
          return {
            ...item,
            is_opted: !!val,
            sub_types: [...item.sub_types].map((subItem) => {
              if (val) {
                // force check on the a sub items as main item checked
                return { ...subItem, is_opted: true }
              } else {
                // force uncheck on the a sub items as main item unchecked
                return { ...subItem, is_opted: false }
              }
            }),
          }
        } else {
          if (val) {
            // checked on the a main item with no sub_types
            return { ...item, is_opted: true }
          } else {
            // unchecked on the a main item with no sub_types
            return { ...item, is_opted: false }
          }
        }
      } else {
        return { ...item }
      }
    })

    tempChangesArr.push({ type, is_opted: val })
    let temp2Arr = [...tempArr]

    for (let item of temp2Arr) {
      if (item.type === type && item.sub_types.length > 0) {
        tempChangesArr = tempChangesArr.filter((change) => change.type !== item.type)
        for (let subItem of item.sub_types) {
          tempChangesArr.push({ type: subItem.type, is_opted: !!val })
        }
      }
    }
    setChangesArr([...changesArr, ...tempChangesArr])
    handleIndeterminationCalc(tempArr)
  }

  const handleSubChange = (val, type) => {
    let newPNTypesArr = [...pnTypesArr]

    let tempArr = newPNTypesArr.map((item) => {
      return {
        ...item,
        sub_types: [...item.sub_types].map((subItem) => {
          if (subItem.type === type) {
            if (val) {
              // checked on the a subtype
              return { ...subItem, is_opted: true }
            } else {
              // unchecked on the a subtype
              return { ...subItem, is_opted: false }
            }
          } else {
            return { ...subItem }
          }
        }),
      }
    })
    setChangesArr([...changesArr, { type, is_opted: val }])
    handleIndeterminationCalc(tempArr)
  }

  const handleIndeterminationCalc = (arr) => {
    for (let item of arr) {
      item.newSubCheckedCount = 0
      for (let subItem of item.sub_types) {
        if (subItem.is_opted) {
          item.newSubCheckedCount++
        }
      }

      if (item.sub_types.length > 0) {
        if (item.newSubCheckedCount === 0) {
          item.is_opted = false
          item.isIndeterminated = false
        }
        if (item.newSubCheckedCount > 0 && item.newSubCheckedCount < item.sub_types.length) {
          item.is_opted = false
          item.isIndeterminated = true
        }
        if (item.newSubCheckedCount === item.sub_types.length) {
          item.is_opted = true
          item.isIndeterminated = false
        }
      }
    }

    let optedArr = findingOptedInItems(arr)
    setUserNewSettings(optedArr)
    setPNTypesArr(arr)
  }

  useEffect(() => {
    if (changesArr.length > 0) {
      const uniqueChangesArr = makeUnique([...changesArr])
      findDeltaChanges(uniqueChangesArr)
    }
  }, [changesArr])

  const makeUnique = (arr) => {
    let reveresArr = [...arr].reverse()

    return reveresArr.filter((value, index, self) => index === self.findIndex((t) => t.type === value.type))
  }

  const findDeltaChanges = (uniqueChangesArr) => {
    let tempArr = []
    let valuesOnly = []

    for (let item of uniqueChangesArr) {
      valuesOnly = [...valuesOnly, item.type]
      for (let setting of getUserCurrentSettings) {
        // add any item unchecked
        if (item.type === setting && !item.is_opted) {
          tempArr.push(item)
        }
      }
    }

    // add any item checked newly
    let newItems = []
    let newlyCheckedTypes = valuesOnly.filter((f) => !getUserCurrentSettings.includes(f))
    for (let type of newlyCheckedTypes) {
      // add any item unchecked
      newItems.push({ type, is_opted: true })
    }
    setDeltaArr([...tempArr, ...newItems])
  }

  useEffect(() => {
    if (deltaArr.length > 0) {
      buildPostReq()
    }
  }, [deltaArr])

  const buildPostReq = () => {
    let userPrefs = [...userPreferences.team_member_preference_list]
    let tempArr

    tempArr = [...userPrefs]

    for (let item of tempArr) {
      if (item.sub_types.length > 0) {
        for (let subItem of item.sub_types) {
          for (let change of deltaArr) {
            if (subItem.type === change.type) {
              item.isChangeInSubType = true
              item.is_opted = false
            }
          }
        }
      }
    }

    let temp2Arr = tempArr.map((item) => {
      if (item.sub_types.length > 0) {
        return {
          ...item,
          sub_types: item.sub_types.map((subItem) => {
            for (let change of deltaArr) {
              if (subItem.type === change.type) {
                return { ...subItem, is_opted: change.is_opted }
              }
            }
          }),
        }
      } else {
        for (let change of deltaArr) {
          if (item.type === change.type) {
            return { ...item, is_opted: change.is_opted }
          }
        }
      }
    })

    let temp3Arr = temp2Arr.filter(
      (item) =>
        item !== undefined && ((item.sub_types?.length > 0 && item.isChangeInSubType) || item.sub_types?.length === 0),
    )

    let temp4Arr = temp3Arr.map((item) => {
      if (item.sub_types.length > 0) {
        return {
          ...item,
          sub_types: item.sub_types.filter((subItem) => subItem !== undefined),
        }
      } else {
        return { ...item }
      }
    })

    let temp5Arr = temp4Arr.map((item) => {
      if (item.sub_types.length > 0) {
        return {
          type: item.type,
          is_opted: item.is_opted,
        }
      } else {
        return {
          type: item.type,
          is_opted: item.is_opted,
          type_id: item.type_id,
        }
      }
    })

    setPostReq(temp5Arr)
  }

  const renderComponent = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <Paper sx={styles.mainScrollContainer}>
          {showLoader ? renderLoader() : renderAccordion()}
          {showLoader ? null : renderActionControls()}
        </Paper>
      </div>
    )
  }

  const renderLoader = () => {
    return (
      <>
        <span style={styles.loaderStyles}>
          <InnerLoader size={48} />
        </span>
      </>
    )
  }

  const handleSubmit = () => {
    // add post api call here to persis the changes,
    // send api even if userNewSettings array length zero

    let postBody = {
      worker_id: padEmpIdWithZeros(userInfo?.empid, 10),
      push_notification_types: postReq,
    }
    dispatch(postUserPreferences(postBody))
    setSubmitClicked(true)
  }

  const handleCancel = () => {
    if (isModified) {
      openCancelDialog()
    }
  }

  const openCancelDialog = () => {
    setCancelDialogOpen(true)
  }

  const closeCancelDialog = () => {
    setCancelDialogOpen(false)
  }

  const cancelDialogNoButtonClikced = () => {
    closeCancelDialog()
  }

  const cancelDialogYesButtonClikced = () => {
    setCancelDialogOpen(false)
    setUserNewSettings([...getUserCurrentSettings])
    processPNTypeList()
  }

  function hasPNSettingsUpdated() {
    // check if user has updated the push notification settings.
    if (!submitClicked) {
      let is_same =
        userNewSettings.length === getUserCurrentSettings.length &&
        userNewSettings.every(function (element) {
          return getUserCurrentSettings.includes(element)
        })

      return !is_same
    } else {
      return false
    }
  }

  const renderActionControls = () => {
    return (
      <div style={styles.actionControlsContainer}>
        <div style={styles.action}>
          {!isModified ? (
            <Link style={styles.disableLinkCls} to="" onClick={handleCancel}>
              CANCEL
            </Link>
          ) : (
            <Link style={styles.linkCls} to="" onClick={handleCancel}>
              CANCEL
            </Link>
          )}
        </div>
        <div style={styles.action}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleSubmit}
            sx={styles.mobileButton}
            disabled={!isModified}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  const renderAccordion = () => {
    return (
      <div>
        {pnTypesArr.map((item, index) => (
          <Accordion key={`pn_main_item+${index}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label={`Expand-${index}`}
              aria-controls={`push-notification-${index}`}
              id={`pn-${index}`}
            >
              <CheckBoxTME
                label={item.label}
                indeterminate={item.isIndeterminated}
                checked={item.is_opted}
                handleChange={(val) => handleMainChange(val, item.type)}
                disabled={item.is_mandatory}
              />
            </AccordionSummary>
            <AccordionDetails sx={styles.AccordionDetailsCls}>
              <div>
                <Typography color="textSecondary">{item.description}</Typography>
              </div>
              {item.sub_types.length > 0 &&
                item.sub_types.map((subItem, subTypeIndex) => (
                  <div key={`pn_secondary_item+${subTypeIndex}`}>
                    <CheckBoxTME
                      label={subItem.type}
                      indeterminate={false}
                      checked={subItem.is_opted}
                      handleChange={(val) => handleSubChange(val, subItem.type)}
                    />
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    )
  }

  const loader = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <div style={styles.loadingIconContainer}>
          <div>
            <CircularProgress size={48} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <HeaderTitle title={PAGE_TITLE} />
      <CancelPromptDialog
        openCancelDialog={cancelDialogOpen}
        closeCancelDialog={closeCancelDialog}
        cancelDialogNoButtonClikced={cancelDialogNoButtonClikced}
        cancelDialogYesButtonClikced={cancelDialogYesButtonClikced}
      />
      <ExitPromptDialog madeUpdates={hasPNSettingsUpdated()} />
      {userPreferencesLoading === 'N' ? renderComponent() : loader()}
    </>
  )
}

export default PushNotification
