import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import {
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
  ERROR_CODES_SHOW_MESSAGE,
  SUCCESS_ON_SAVE,
  UNABLE_TO_SAVE,
} from '../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'
import useSyncMutation from '../utils/hooks/useSyncMutation'
import { useUser } from '../../auth/hooks/useUser'

async function putMassVacation(massVacation, action, workerId, locationId) {
  let requestBody = {
    worker_id: workerId,
    location: locationId,
    request_id: massVacation?.requestId,
    action: action,
  }

  if (action === 'ADD' || action === 'EDIT') {
    requestBody = {
      ...requestBody,
      title: massVacation?.title,
      priority: massVacation?.priority,
      shift_start_time: moment(massVacation?.shiftStartTime).format('HH:mm'),
      mass_vacation_days: massVacation.selectedDates,
    }
  }

  let apiUrl = `${apiConfig.timeOff.url}` + '/mass_vacation_requests' + '?key=' + `${apiConfig.timeOff.key}`

  const { data } = await axios.put(apiUrl, requestBody, BASIC_REQUEST_CONFIG)

  return data
}

export default function usePutMassVacation() {
  const { isAuthenticated, login } = useAuth()
  const { userData, locationData } = useUser().user
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  let requestType = useSelector((state) => state.requests.requestType)

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  const dispatch = useDispatch()

  // action: 'ADD' || 'EDIT' || 'DELETE'
  return useSyncMutation({
    mutationFn: ({ massVacation, action }) =>
      putMassVacation(massVacation, action, userData.worker_id, locationData.location_id),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['requests', { requestType }])
      navigate('/team-member/requests/mass_vacation')
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
    },
    onError: (error, variables) => {
      const loggingInfo = {
        message: 'PUT /mass_vacation_requests had a error.',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          message: error?.message,
          code: error?.code,
          workerId: userData.worker_id,
          location: locationData.location_id,
          variables: variables,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
      if (ERROR_CODES_SHOW_MESSAGE.includes(error?.response?.data.code)) {
        dispatch(showNotificationError(true, error?.response?.data.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(error?.response?.data, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
          ),
        )
      }
    },
  })
}
