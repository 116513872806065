import { ILocationsResponse } from '../api/location/responses/locations'
import { IFeature, IUserAccess } from '../api/userAccess/responses/userAccess'
import { padEmpIdWithZeros } from '../utils/EmployeeId'
import { availableFeatures, availablePermissions, availableViews } from './constants'

// typescript does not officially support deriving a union type from an array's values. This is a workaround.
export type FeatureName = (typeof availableFeatures)[number]
export type ViewName = (typeof availableViews)[number]
export type PermissionName = (typeof availablePermissions)[number]

export enum UnauthorizedCausedByEnum {
  FEATURE = 'noFeature',
  PUNCHIN = 'noPunchIn',
  PUNCHOUT = 'noPunchOut',
}

export type AuthorizedAccess = {
  hasAccess: boolean
  causedBy:
    | UnauthorizedCausedByEnum.FEATURE
    | UnauthorizedCausedByEnum.PUNCHIN
    | UnauthorizedCausedByEnum.PUNCHOUT
    | null
  causedByFeature: IFeature | null
  isCompensable: boolean | null
}

type CompensableRequirement = {
  requirement: UnauthorizedCausedByEnum | null
  isCompensable: boolean | null
}

export function getShortPersona(persona: string): string {
  const parts = persona.split(':')
  if (parts.length >= 2) {
    return `${parts[0]}:${parts[1]}` // office:admin
  }

  return persona
}

export function getPersonaNoLocation(persona: string): string {
  const parts = persona.split(':')
  if (parts.length >= 3) {
    return `${parts[0]}:${parts[1]}:${parts[2]}` // office:admin:EX
  }

  return persona
}

export class User {
  public userData: Readonly<IUserAccess>
  public locationData: Readonly<ILocationsResponse>

  constructor(userData: IUserAccess, locationData: ILocationsResponse) {
    this.userData = {
      ...userData,
      worker_id: padEmpIdWithZeros(userData.worker_id, 10),
      persona_no_location: getPersonaNoLocation(userData.persona),
      short_persona: getShortPersona(userData.persona),
    }
    this.locationData = { ...locationData, location_id: locationData.location_id?.toString().padStart(4, '0') }
  }

  hasAccess(
    allowedFeatures: FeatureName[],
    view: ViewName,
    allowedPermissions: PermissionName[],
    checkCompensable: boolean,
  ): AuthorizedAccess {
    let authAccess = {
      hasAccess: false,
      causedBy: null,
      causedByFeature: null,
      isCompensable: null,
    } as AuthorizedAccess

    if (!allowedFeatures.length) return authAccess

    allowedFeatures.forEach((allowedFeature) => {
      // if we have no access and we need a compensable check for this feature, we can break loop.
      if (!authAccess.hasAccess && authAccess.isCompensable !== null) return
      let feature = this.userData.features?.[allowedFeature]?.[view] || null
      let hasPermissionAccess = false

      if (feature) {
        hasPermissionAccess = allowedPermissions.some((allowedPermission) => {
          return feature?.permission?.includes(allowedPermission)
        })
      }

      if (feature && hasPermissionAccess) {
        authAccess.hasAccess = true
        if (checkCompensable) {
          let compensableRequirement = this.getCompensableRequirement(feature)

          if (compensableRequirement.requirement) {
            authAccess.causedBy = compensableRequirement.requirement
            authAccess.isCompensable = compensableRequirement.isCompensable
            authAccess.causedByFeature = feature
            authAccess.hasAccess = false
          }
        }
      }
    })

    // if we have gon through all allowed features and still no hasAccess and we don't
    // require a compensable check, set cause by to noFeature
    if (!authAccess.hasAccess && authAccess.isCompensable === null) {
      authAccess.causedBy = UnauthorizedCausedByEnum.FEATURE
    }

    return authAccess
  }

  getCompensableRequirement(feature: IFeature): CompensableRequirement {
    let compensableRequirement: CompensableRequirement = { requirement: null, isCompensable: null }

    // is_compensable = undefined -> no compensable check required
    if (feature?.is_compensable === undefined) return compensableRequirement

    compensableRequirement.isCompensable = feature.is_compensable

    if (feature.is_compensable) {
      // is_compensable = true -> must be punched in
      compensableRequirement.requirement = UnauthorizedCausedByEnum.PUNCHIN
    } else {
      // is_compensable = false -> must be punched out
      compensableRequirement.requirement = UnauthorizedCausedByEnum.PUNCHOUT
    }

    return compensableRequirement
  }

  /**
   * Helper function to determine if any features in a list care about compensability.
   * Note the user has to have the feature in order for us to check.
   * @param features string[]
   * @returns boolean
   */
  anyCompensable(features: string[]): boolean {
    return features.some((feature) => {
      let currentFeature = this.userData.features?.[feature as FeatureName]
      if (currentFeature) {
        return Object.entries(currentFeature).some(([_, value]) => !(value.is_compensable === undefined))
      }

      return false
    })
  }

  /**
   * Helper function to check a users feature access for a singular feature.
   * @param feature FeatureName
   * @param view ViewName
   * @param permission PermissionName
   * @param strict boolean - checks permission strictly, meaning only 1 (ex. needing to have "read" only)
   * @returns boolean
   */
  is(feature: FeatureName, view: ViewName, permission?: PermissionName, strict: boolean = false): boolean {
    const featureView = this.userData.features?.[feature]?.[view]
    if (!featureView) {
      return false
    }
    if (permission) {
      const permissions = featureView.permission.split('_')
      if (strict && permissions.length > 1) {
        return false
      }
      if (!permissions.includes(permission)) {
        return false
      }
    }
    return true
  }
}
