import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import { isValid, startOfDay } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import usePutMassVacation from '../../../api/requests/usePutMassVacation'
import { setBackToComponent } from '../../../store/header/actionCreator'
import { formatMinutesToDisplay, getDateTimeWithNoTimezone, massVacationManager } from '../../../utils/DateUtil'
import { charRegex, emojiRegex, getOrdinalNumber, pluralize } from '../../../utils/strings'
import ExitPromptDialog from '../../Settings/ExitPromptDialog'
import MultiDatePicker from '../../common/calendar/MultiDatePicker'
import TimePicker from '../../common/calendar/TimePicker'
import MainLayout, { MainContentDiv } from '../../common/layout/Layouts'

const initialData = {
  requestId: null,
  title: '',
  priority: '',
  shiftLengthMinutes: '',
  selectedDates: [],
  shiftStartTime: null,
}

const convertRequestToMassVacation = (request, shiftLengthMinutes) => {
  let priority = request.priority < 1 ? '' : request.priority
  let editableExistingRequest = { ...initialData }
  editableExistingRequest.requestId = request.request_id
  editableExistingRequest.title = request.title
  editableExistingRequest.priority = priority
  editableExistingRequest.shiftLengthMinutes = shiftLengthMinutes
  editableExistingRequest.shiftStartTime = getDateTimeWithNoTimezone(request.request_details[0].start_time)
  editableExistingRequest.selectedDates = request.request_details.reduce((accumulator, detail) => {
    let elementToAdd = startOfDay(new Date(detail?.start_time))
    if (!accumulator.includes(elementToAdd)) {
      accumulator.push(elementToAdd)
    }

    return accumulator
  }, [])

  return editableExistingRequest
}

export default function RequestMassVacation() {
  // can be undefined. if undefined then we assume in edit mode
  const { requestId } = useParams()
  const { state } = useLocation()
  const mvManager = massVacationManager()

  const titleRef = useRef(null)

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus()
    }
  }, [titleRef])

  const [massVacation, setMassVacation] = useState({
    ...initialData,
    shiftLengthMinutes: state?.data.shiftLengthMinutes,
  })
  const [originalMassVacation, setOriginalMassVacation] = useState({
    ...initialData,
    shiftLengthMinutes: state?.data.shiftLengthMinutes,
  })
  const [massVacationErrors, setMassVacationErrors] = useState({
    title: { error: false, message: '' },
    priority: { error: false, message: 'Please select a priority' },
    shiftLengthMinutes: { error: false, message: 'Error loading shift length' },
    selectedDates: { error: false, message: '' },
    shiftStartTime: { error: false, message: 'Please select the shift start time' },
  })

  const dispatch = useDispatch()
  const theme = useTheme()

  useEffect(() => {
    dispatch(setBackToComponent('/team-member/requests/mass_vacation'))
  }, [dispatch])

  useEffect(() => {
    if (state?.data?.request) {
      let mv = convertRequestToMassVacation(state?.data?.request, state?.data?.shiftLengthMinutes)
      setMassVacation(mv)
      setOriginalMassVacation(mv)
    }
  }, [state?.data?.request, state?.data?.shiftLengthMinutes])

  const hasChanges = useCallback(() => {
    return JSON.stringify(originalMassVacation) !== JSON.stringify(massVacation)
  }, [massVacation, originalMassVacation])

  const hasErrors = useCallback(() => {
    return (
      massVacationErrors.priority.error ||
      massVacation.selectedDates.length === 0 ||
      massVacationErrors.shiftStartTime.error
    )
  }, [massVacationErrors.priority.error, massVacation.selectedDates.length, massVacationErrors.shiftStartTime.error])

  const handleMassVacationChange = (key, value) => {
    setMassVacation((prevValue) => {
      let newValue = { ...prevValue, [key]: value }
      if (key !== 'title' && key !== 'selectedDates') {
        if (!newValue[key] || (key === 'shiftStartTime' && !isValid(value))) {
          handleMassVacationErrors(key, true)
        } else {
          handleMassVacationErrors(key, false)
        }
      }
      return newValue
    })
  }

  const handleMassVacationErrors = (key, error) => {
    setMassVacationErrors((prevValue) => {
      return { ...prevValue, [key]: { ...prevValue[key], error } }
    })
  }

  if (massVacation) {
    return (
      <>
        <MainLayout>
          <Grid container padding={1} sx={{ backgroundColor: '#fff' }}>
            {requestId ? (
              <Grid container justifyContent={'center'}>
                <Typography color="primary">Editing existing request</Typography>{' '}
              </Grid>
            ) : (
              <></>
            )}
            {mvManager.submitDisabled ? (
              <Grid container justifyContent={'center'}>
                <Typography color="primary">Mass vacation submission is disabled</Typography>{' '}
              </Grid>
            ) : (
              <></>
            )}
            <FormControl
              size="small"
              sx={{ m: 1 }}
              fullWidth
              error={massVacationErrors['title'].error}
              disabled={mvManager.submitDisabled}
              variant="standard"
              required={false}
            >
              <InputLabel variant="standard" id="mv-title-label">
                Request Title
              </InputLabel>
              <Input
                value={massVacation.title}
                labelid="mv-title-label"
                aria-describedby="mv-title-helper-text"
                id="mv-title"
                data-cy="vacationName"
                inputProps={{ maxLength: 60 }}
                onChange={(event) => {
                  if (emojiRegex.test(event.target.value) || !charRegex.test(event.target.value)) {
                    return null
                  }
                  return handleMassVacationChange('title', event.target.value)
                }}
                inputRef={titleRef}
              />
              <FormHelperText id="mv-title-helper-text">
                {massVacationErrors['title'].error
                  ? massVacationErrors['title'].message
                  : 'Give request a friendly name (optional)'}
              </FormHelperText>
            </FormControl>
            <FormControl
              size="small"
              sx={{ m: 1 }}
              fullWidth
              error={massVacationErrors['priority'].error}
              disabled={mvManager.submitDisabled}
              variant="standard"
              required={true}
            >
              <InputLabel variant="standard" id="select-priority-label">
                Priority
              </InputLabel>
              <Select
                value={massVacation.priority}
                labelid="select-priority-label"
                aria-describedby="select-priority-helper-text"
                id="select-priority"
                data-cy="selectPriority"
                onChange={(event) => handleMassVacationChange('priority', event.target.value)}
                onBlur={() => (!massVacation.priority ? handleMassVacationErrors('priority', true) : null)}
                displayEmpty
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((priorityValue) => {
                  return (
                    <MenuItem key={`prio_${priorityValue}`} value={priorityValue}>
                      {priorityValue}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText id="select-priority-helper-text">
                {massVacationErrors['priority'].error
                  ? massVacationErrors['priority'].message
                  : 'If priority is already selected for another request, that requests priority will need to be updated.'}
              </FormHelperText>
            </FormControl>
            <FormControl
              size="small"
              sx={{ m: 1 }}
              fullWidth
              error={massVacationErrors['shiftLengthMinutes'].error}
              disabled={mvManager.submitDisabled}
              focused={massVacationErrors['shiftLengthMinutes'].error}
              variant="standard"
            >
              <InputLabel variant="standard" id="select-shiftLengthMinutes-label">
                Your Shift Length
              </InputLabel>
              <Input
                value={formatMinutesToDisplay(massVacation.shiftLengthMinutes, 'large')}
                labelid="select-shiftLengthMinutes-label"
                aria-describedby="select-shiftLengthMinutes-helper-text"
                id="select-shiftLengthMinutes"
                disabled={true}
              />
            </FormControl>
            <TimePicker
              label="Shift Start Time"
              defaultValue={massVacation.shiftStartTime}
              onChange={(newValue) => handleMassVacationChange('shiftStartTime', newValue)}
              disabled={mvManager.submitDisabled}
              slotProps={{
                textField: {
                  required: true,
                  sx: { m: 1 },
                  fullWidth: true,
                  helperText: massVacationErrors['shiftStartTime'].error
                    ? massVacationErrors['shiftStartTime'].message
                    : 'Select your regular shift start time',
                  error: massVacationErrors['shiftStartTime'].error,
                  onBlur: () =>
                    !massVacation.shiftStartTime ? handleMassVacationErrors('shiftStartTime', true) : null,
                },
              }}
            />
            <Grid container justifyContent={'center'}>
              <MultiDatePicker
                minDate={mvManager?.minDate}
                maxDate={mvManager?.maxDate}
                disabled={mvManager?.submitDisabled}
                maxDaysInRange={14}
                initialValue={massVacation.selectedDates || []}
                onDatesChange={(selections) => handleMassVacationChange('selectedDates', selections)}
              />
            </Grid>
            {/* <Grid container>
              <>
                <Grid container>
                  <Typography>
                    Select which dates are required for this vacation to be accepted (partial approval)
                  </Typography>
                </Grid>
                {massVacation.selectedDates.map((date) => {
                  return (
                    <Grid item container>
                      <PartialApproval date={date} />
                    </Grid>
                  )
                })}
              </>
            </Grid> */}
          </Grid>
        </MainLayout>
        <MainContentDiv container sx={{ borderTop: `1px solid ${theme.palette.tertiary.main}` }}>
          <Paper
            square
            elevation={1}
            sx={{ display: 'flex', width: '100%', height: '100%', padding: '10px', justifyContent: 'right' }}
          >
            <RequestFooter
              massVacation={massVacation}
              hasError={hasErrors}
              hasChanges={hasChanges}
              isEditingExisting={!!requestId}
            />
          </Paper>
        </MainContentDiv>
      </>
    )
  }
}

// function PartialApproval({ date }) {
//   return <FormControlLabel required control={<Checkbox />} label={format(date, 'MM-dd-yy')} />
// }

function RequestFooter({ massVacation, hasError, hasChanges, isEditingExisting }) {
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  let isError = hasError() || massVacation?.priority === '' || massVacation?.shiftStartTime === null

  const handleCloseDialog = () => {
    setSubmitDialogOpen(false)
    setIsSubmitting(false)
  }

  const handleSubmit = () => {
    setSubmitDialogOpen(true)
    setIsSubmitting(true)
  }

  return (
    <>
      <Button
        data-cy="requestTimeOffCancel"
        color="primary"
        sx={{ marginRight: '20px' }}
        onClick={() => navigate('/team-member/requests/mass_vacation')}
      >
        Cancel
      </Button>
      <Button
        data-cy="requestTimeOffSubmit"
        variant="contained"
        type="submit"
        aria-haspopup="true"
        color="primary"
        onClick={handleSubmit}
        disabled={isError}
        aria-disabled={isError}
      >
        Submit
      </Button>
      <SubmitMassVacationModal
        submitDialogOpen={submitDialogOpen}
        handleCloseDialog={handleCloseDialog}
        massVacation={massVacation}
      />
      {!isSubmitting && isEditingExisting ? <ExitPromptDialog madeUpdates={hasChanges()} /> : <></>}
    </>
  )
}

const SubmitMassVacationModal = ({ submitDialogOpen, handleCloseDialog, massVacation }) => {
  const { mutate } = usePutMassVacation()
  const [disableButton, setDisableButton] = useState(false)

  const handleYes = () => {
    setDisableButton(true)
    let action = massVacation.requestId ? 'EDIT' : 'ADD'
    mutate(
      { massVacation, action },
      {
        onSettled: () => {
          handleCloseDialog()
          setDisableButton(false)
        },
      },
    )
  }

  const handleNo = () => {
    handleCloseDialog()
  }

  let numberOfDays = massVacation?.selectedDates.length

  return (
    <Dialog
      data-cy="requestTimeOffSubmitDialog"
      open={submitDialogOpen}
      aria-describedby="save-dialog-content"
      aria-labelledby="save-dialog-title"
      aria-modal={true}
      keepMounted
    >
      <DialogTitle id="save-dialog-title">Save Mass Vacation?</DialogTitle>
      <DialogContent id="save-dialog-content">
        <DialogContentText id="save-dialog-priority" color={'textPrimary'}>
          Saving your
          <span style={{ fontWeight: '600', color: 'black' }}>
            &nbsp;{getOrdinalNumber(massVacation.priority)} priority&nbsp;
          </span>
          mass vacation request for a total of
          <span style={{ fontWeight: '600', color: 'black' }}>&nbsp;{pluralize(numberOfDays, 'day')}</span>
        </DialogContentText>
        <DialogContentText id="save-dialog-description" marginTop={'20px'}>
          You can make edits to this request up until the submission period is over.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          NO
        </Button>
        <Button onClick={handleYes} color="primary" variant="contained" aria-label="Yes" disabled={disableButton}>
          {disableButton ? <CircularProgress size={'1rem'} /> : 'YES'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
