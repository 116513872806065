import { Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useAuth } from '@praxis/component-auth'
import { addDays } from 'date-fns'
import { get } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Element } from 'react-scroll'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_SHIFT_SWAP,
  INITIATED_SHIFT_SWAP_MESSAGE,
  NO_RESULTS_FOUND,
  RULES_VIOLATION_ERROR,
  RULES_VIOLATION_ERR_MSG,
  RULES_VIOLATION_ERR_MSG_FOR_SWAP,
  UNABLE_TO_SAVE,
} from '../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { clearInitiatePostRequests, getShiftsForSwap } from '../../store/swapShift/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { formatErrorCode } from '../../utils/ErrorHandling'
import { SCREEN_NAME_SWAP_SHIFT } from '../../utils/ScreenName'
import DisplaySegmentShiftSwap from '../DisplaySegments/DisplaySegmentShiftSwap'
import HeaderTitle from '../Header/HeaderTitle'
import InnerLoader from '../Loader/InnerLoader'
import FilterOptions from './FilterOptions'
import InitiateSwapRequestModal from './InitiateSwapRequestModal'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  loadingIconContainer: theme.loadingIconContainer,
  highlights: {
    fontWeight: 'bold',
    fontSize: '105%',
    paddingTop: '2px',
  },
  highlightsContainer: {
    textAlign: 'center',
    borderBottom: `4px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em',
    justifyContent: 'center',
  },
  displaySegmentContainerModal: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1em',
    justifyContent: 'center',
  },
  displaySegmentContainerSwap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.2em',
    justifyContent: 'center',
  },
  titleContainerModal: {
    padding: '16px 24px 5px',
  },
  shiftSwapButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  padLeftForIcons: {
    paddingLeft: '2.4em',
  },
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  jobContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobContainerAtPopup: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobName: {
    paddingTop: '3px',
  },
  iconMargin: {
    marginRight: '.25em',
  },
  personIconMargin: {
    marginRight: '.6em',
  },
  indicator: {
    marginTop: '-2px',
    marginLeft: '-15px',
    marginRight: '5px',
    fontSize: '24px',
    color: 'black',
    zIndex: '1',
  },
  jobNameContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
  },
  paycodeEditsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  paycodeEdits: {
    fontWeight: '300',
  },
  dateHeading: {
    fontWeight: 'bold',
    fontSize: '0.9em',
  },
  dateHeadingSwap: {
    fontWeight: 'bold',
    fontSize: '0.9em',
    color: '#000000',
  },
  lastEdit: {
    color: theme.palette.secondary.light,
  },
  '@media (min-width: 640px)': {
    mainScrollContainer: {
      marginLeft: `calc(100vw/2 - (${theme.mainScrollContainer.maxWidth}/2))`,
      marginRight: `calc(100vw/2 - (${theme.mainScrollContainer.maxWidth}/2))`,
    },
  },
  dividerBox: {
    width: '100%',
    marginTop: '10px',
  },
  postShiftButton: {
    minWidth: '50px',
  },
  tableList: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  tableItem: {
    display: 'table-row',
  },
  listItemDiv: {
    position: 'relative',
  },
  loaderStyles: {
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    padding: '32px',
  },
  innerLoaderLabelStyles: {
    paddingLeft: '6px',
    fontColor: '#cc0000',
    color: '#CC0000',
  },
  addtionalJobsContainer: {
    paddingTop: '1em',
  },
  dateContainer: {
    display: 'flex',
  },
  shiftLabelContainer: {
    marginTop: '-3px',
    marginLeft: '12px',
    display: 'inline-Block',
  },
  shiftLabelContainerInModal: {
    marginTop: 1,
    display: 'inline-Block',
  },
  additionalShiftLabels: {
    margin: '2px 6px',
  },
  shiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
  ownShiftContainer: {
    margin: '10px',
    padding: '10px',
    border: '1px solid #aeaeae',
    position: 'relative',
  },
  requestContainer: {
    margin: '10px',
    padding: '10px',
    border: '1px solid #aeaeae',
    borderRadius: 7,
    position: 'relative',
  },
  requestContainerModal: {
    margin: '-4px',
    padding: '5px 10px 0 10px',
    border: '1px solid #aeaeae',
    borderRadius: '7px',
    position: 'relative',
  },
  likesContainer: {
    display: 'flex',
    position: 'absolute',
    right: 10,
    bottom: 10,
    '& span': {
      paddingLeft: '4px',
      fontSize: '0.9em',
    },
  },
  shiftCountMessageContainer: {
    padding: '10px',
    fontStyle: 'italic',
  },
  verticalGap: {
    padding: '10px 5px',
    textAlign: 'center',
    width: '90%',
  },
  pb10: {
    paddingBottom: '10px',
  },
  gradiantBG: {
    background: 'linear-gradient(to bottom, #bdbdbd 0%, #eeeeee 100%)',
    borderBottom: '1px solid #EEEEEE',
    height: 100,
    position: 'absolute',
    zIndex: 100,
  },
  shiftForeGround: {
    position: 'relative',
  },
  dialogPaper: {
    margin: '5px',
  },
  messageContainer: {
    padding: '10px 20px ',
    fontStyle: 'italic',
    minHeight: 370,
  },
  messageContainerHeader: {
    textAlign: 'center',
    margin: '10px 0',
  },
  redTextMessage: {
    textAlign: 'center',
    margin: '10px 0',
    color: '#cc0000',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  filterOptionContainer: {
    margin: '5px 10px',
  },
  loadMore: {
    display: 'block',
    border: 0,
    margin: '1em auto',
    fontSize: '1em',
    color: '#cc0000',
    fontWeight: '500',
    fontFamily: '$sans',
    cursor: 'pointer',
    textDecorationLine: 'underline',
  },
  arrow: {
    border: '#cc0000',
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    padding: '3px',
    transform: 'rotate(45deg)',
    webkitTransform: 'rotate(45deg)',
  },
})

const PAGE_TITLE = 'Search Shifts for Swap'

const messageStyles = {
  messageContainer: {
    padding: '10px 20px ',
    fontStyle: 'italic',
    minHeight: 370,
  },
  messageContainerHeader: {
    textAlign: 'center',
    margin: '10px 0',
  },
  redTextMessage: {
    textAlign: 'center',
    margin: '10px 0',
    color: '#cc0000',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
}

const PrintInstructionMessage = () => {
  return (
    <div style={messageStyles.messageContainer}>
      <Typography variant="body2" sx={messageStyles.messageContainerHeader}>
        <b>Points to remember</b>
      </Typography>
      <Typography variant="body2"> - Double check your shift you wish to swap with.</Typography>
      <Typography variant="body2"> - Check and modify search options to get appropriate results</Typography>
    </div>
  )
}

const PrintNoResultsMessage = () => {
  return (
    <div style={messageStyles.messageContainer}>
      <Typography variant="body1" sx={messageStyles.redTextMessage}>
        <span>{NO_RESULTS_FOUND}</span>
      </Typography>
    </div>
  )
}

const SearchSwapShifts = () => {
  const auth = useAuth()
  const { isAuthorized, login, session } = auth
  const { userInfo } = session
  const theme = useTheme()
  const styles = getStyles(theme)
  const userDetails = useSelector((state) => state.layout.userDetails)
  const screenAccess = useSelector((state) => state.layout.screenAccess)
  const [showMsgScreen, setShowMsgScreen] = useState(true)
  const [open, setOpen] = useState(false)
  const [expand, setExpand] = useState(true)
  const [selectedShift, setSelectedShift] = useState({})
  const [shiftData, setShiftData] = useState({})
  const dispatch = useDispatch()
  const shiftsForSwap = useSelector((state) => state.swapShift.shiftsForSwap)
  const shiftsForSwapError = useSelector((state) => state.swapShift.shiftsForSwapError)
  const currentShift = useSelector((state) => state.swapShift.currentShiftForRequestor)
  const requestToSwapPostResponse = useSelector((state) => state.swapShift.requestToSwapPostResponse)
  const requestToSwapErrResponse = useSelector((state) => state.swapShift.requestToSwapErrResponse)
  const navigate = useNavigate()
  const [resetActions, makeResetActions] = useState(false)
  const [showLoader, setLoader] = useState(false)
  const messagesEndRef = useRef(null)
  const [visible, setVisible] = useState(50)
  const [loadMoreBtnVisible, setLoadMoreBtnVisible] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef()
  const [message, setMessage] = useState(<PrintInstructionMessage />)

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && shiftData.team_members_schedules.length > visible) {
          setLoadMoreBtnVisible(true)
        }
      })
      if (node) observer.current.observe(node)
    },
    [hasMore, shiftData, visible],
  )

  useEffect(() => {
    setExpand(true)
    setLoadMoreBtnVisible(false)
    dispatch(clearInitiatePostRequests())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isAuthorized()) {
      login()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])

  useEffect(() => {
    if (shiftsForSwap && Object.keys(shiftsForSwap).length > 0 && Object.keys(currentShift).length > 0) {
      // collapse search options, hide loader, hide general instruction message and show results list when success
      if (shiftsForSwap.total_team_members_schedules > 0) {
        formatData()
        setShowMsgScreen(false)
        setExpand(false)
        setLoader(false)
      } else {
        setMessage(<PrintNoResultsMessage />)
        setShowMsgScreen(true)
        setExpand(false)
        setLoader(false)
      }
    }
    if (!shiftsForSwap) {
      // expand search options, hide loader and show general instruction message when no results
      setExpand(true)
      setLoader(false)
      setMessage(<PrintInstructionMessage />)
      setShowMsgScreen(true)
    }
    if (!currentShift || Object.keys(currentShift).length === 0) {
      navigate('/team-member/schedule')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftsForSwap, currentShift])

  useEffect(() => {
    if (shiftsForSwapError) {
      // expand search options, hide loader and show general instruction message when error response
      setExpand(true)
      setLoader(false)
      setMessage(<PrintInstructionMessage />)
      setShowMsgScreen(true)
      if (
        shiftsForSwapError.code &&
        shiftsForSwapError.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(shiftsForSwapError.code)
      ) {
        dispatch(showNotificationError(true, shiftsForSwapError.message))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftsForSwapError])

  useEffect(() => {
    if (requestToSwapPostResponse) {
      handleClose()
      if (!requestToSwapPostResponse.is_rule_violated) {
        dispatch(showNotificationSuccess(true, INITIATED_SHIFT_SWAP_MESSAGE))
        navigate('/team-member/schedule')
      } else if (requestToSwapPostResponse.is_rule_violated) {
        //Show which  rule is voilated
        dispatch(showNotificationError(true, parseRuleViolationForSwap(requestToSwapPostResponse)))
      }
    } else if (requestToSwapErrResponse) {
      handleClose()
      if (
        requestToSwapErrResponse.code &&
        requestToSwapErrResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(requestToSwapErrResponse.code)
      ) {
        dispatch(showNotificationError(true, requestToSwapErrResponse.message))
      } else if (requestToSwapErrResponse.code && RULES_VIOLATION_ERROR.includes(requestToSwapErrResponse.code)) {
        dispatch(showNotificationError(true, parseRuleViolation(requestToSwapErrResponse)))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE +
              ' ' +
              formatErrorCode(requestToSwapErrResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_SHIFT_SWAP),
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestToSwapPostResponse, requestToSwapErrResponse])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const parseRuleViolation = (errResponse) => {
    let errorMessages = ''
    let message

    if (errResponse.errors && errResponse.errors.length > 0) {
      for (let error of errResponse.errors) {
        errorMessages += '<li>' + error + '</li>'
      }
      message = RULES_VIOLATION_ERR_MSG + '<br /><ul>' + errorMessages + '</ul>'
    } else {
      message = RULES_VIOLATION_ERR_MSG
    }

    return message
  }

  const parseRuleViolationForSwap = (accepterSwapPostResponse) => {
    let ruleViolationsRequesterArr = accepterSwapPostResponse['requester'].rule_violations
    let ruleViolationsAccepterArr = accepterSwapPostResponse['accepter'].rule_violations
    let errMsgRequester = ''
    let errMsgAccepter = ''
    let message = ''

    if (ruleViolationsRequesterArr && ruleViolationsRequesterArr.length > 0) {
      for (let ruleViolation of ruleViolationsRequesterArr) {
        errMsgRequester += '<li>' + ruleViolation.rule_violation_text + '</li>'
      }
      message = 'Requester:' + '<br /><ul>' + errMsgRequester + '</ul><br />'
    }

    if (ruleViolationsAccepterArr && ruleViolationsAccepterArr.length > 0) {
      for (let ruleViolation of ruleViolationsAccepterArr) {
        errMsgAccepter += '<li>' + ruleViolation.rule_violation_text + '</li>'
      }
      message = message + 'Accepter:' + '<br /><ul>' + errMsgAccepter + '</ul>'
    }

    return RULES_VIOLATION_ERR_MSG_FOR_SWAP + '<br />' + message
  }

  const formatData = () => {
    let dataObj = {}
    dataObj.current_shift = currentShift
    dataObj.total_team_members_schedules = shiftsForSwap.total_team_members_schedules
    dataObj.team_members_schedules = excludeOwnShifts(formatShiftDetail())
    setShiftData(dataObj)
  }

  const formatShiftDetail = () => {
    let team_members_schedules = []
    if (get(shiftsForSwap, 'team_members_schedules')) {
      // mutation happens inside formatJobs(), so let's make a copy
      let shiftsForSwapCopy = JSON.parse(JSON.stringify(shiftsForSwap))
      shiftsForSwapCopy.team_members_schedules.forEach((tmSchedules) => {
        if (get(tmSchedules, 'schedules')) {
          tmSchedules.schedules.forEach((schedule) => {
            let shiftObj = {}
            shiftObj.team_member_number = tmSchedules.team_member_number
            shiftObj.team_member_name = `${tmSchedules.first_name} ${tmSchedules.last_name}`
            shiftObj.short_name = tmSchedules.short_name
            shiftObj.schedule_date = schedule.schedule_date
            shiftObj.shift_start = schedule.shift_start
            shiftObj.shift_end = schedule.shift_end
            shiftObj.shift_length = schedule.shift_length
            if (get(schedule, 'display_segments')) {
              schedule.display_segments.forEach((shift) => {
                shiftObj.jobs = shift.jobs.length > 0 ? formatJobs(shift.jobs) : shift.jobs
                shiftObj.job_name = shift.job_name
                shiftObj.org_path = shift.job_path
                shiftObj.org_ids_id = shift.org_ids_id
                shiftObj.total_jobs = shift.total_jobs
                shiftObj.location = shift.location
                shiftObj.shift_label = shift.shift_label ? shift.shift_label : null
              })
            }
            team_members_schedules.push(shiftObj)
          })
        }
      })
    }
    return team_members_schedules
  }

  const formatJobs = (jobs) => {
    let jobList = []

    jobs.forEach((job) => {
      job.org_path = job.job_path
      jobList.push(job)
    })
    return jobList
  }

  const excludeOwnShifts = (team_members_schedules) => {
    let itemToBeRemoved = []
    itemToBeRemoved.push(padEmpIdWithZeros(userInfo?.empid, 10))
    return team_members_schedules.filter((item) => !itemToBeRemoved.includes(item.team_member_number))
  }

  const openRequestSwapDialog = (selectedShift) => {
    setSelectedShift(selectedShift)
    makeResetActions(true)
    setOpen(true)
  }

  const handleClose = () => {
    makeResetActions(false)
    setOpen(false)
  }

  const toggleExpand = (val) => {
    setExpand(val)
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const searchClicked = (filterOptions) => {
    const outputTimeFormat = 'YYYY-MM-DDTHH:mm:ss'
    setLoadMoreBtnVisible(false)
    setLoader(true)
    setShowMsgScreen(false)

    const now = new Date()
    const twoHrsLaterFromNow = new Date(now.getTime() + 2 * 60 * 60 * 1000)
    const twoHrsLaterFromNowForEndTimeCalc = new Date(now.getTime() + 2 * 60 * 60 * 1000)
    const endOfDay = addDays(new Date(twoHrsLaterFromNowForEndTimeCalc.setHours(23, 59, 59)), 2)

    dispatch(
      getShiftsForSwap(
        userDetails.locationid,
        padEmpIdWithZeros(userInfo?.empid, 10),
        filterOptions.selectedTmNumber ? padEmpIdWithZeros(filterOptions.selectedTmNumber, 10) : '',
        stringifyJobs(filterOptions.job),
        filterOptions.shiftLength,
        filterOptions.startsAfterTime
          ? moment(filterOptions.startsAfterTime).format(outputTimeFormat)
          : moment(twoHrsLaterFromNow).format(outputTimeFormat),
        filterOptions.endsBeforeTime
          ? moment(filterOptions.endsBeforeTime).format(outputTimeFormat)
          : moment(endOfDay).format(outputTimeFormat),
        currentShift.schedule_date,
        moment(currentShift.schedule_start)?.format(outputTimeFormat),
        moment(currentShift.schedule_end)?.format(outputTimeFormat),
        currentShift.org_path || '',
      ),
    )
  }

  const stringifyJobs = (jobs) => {
    let orgPath = ''

    if (jobs && jobs.length > 0) {
      for (let job of jobs) {
        orgPath += '&org_path=' + job.org_path
      }
    }

    return orgPath
  }

  const renderComponent = () => {
    return (
      <>
        <InitiateSwapRequestModal
          open={open}
          handleClose={() => handleClose()}
          styles={styles}
          shift={currentShift}
          selectedShift={selectedShift}
          resetActions={resetActions}
        />
        <div style={{ ...styles.mainContainerPosition, ...styles.shiftForeGround }}>
          <div style={styles.gradiantBG} />
          <div style={styles.mainScrollContainer}>
            {renderOwnShift()}
            {renderFilterOptions()}
            {showLoader ? renderLoader() : showMsgScreen ? renderMsgScreen() : renderSwapRequests(visible)}
          </div>
        </div>
      </>
    )
  }

  const renderLoader = () => {
    scrollToBottom()
    return (
      <>
        <span style={styles.loaderStyles}>
          <InnerLoader size={36} />
        </span>
        <div ref={messagesEndRef} />
      </>
    )
  }

  const renderOwnShift = () => {
    return (
      <Element name="scroll-to-element" id="scroll-to-element" className="element">
        <Grid container>
          <Grid item xs={12} md={12}>
            <div style={styles.ownShiftContainer}>
              <DisplaySegmentShiftSwap
                ownShift={true}
                shift={currentShift}
                styles={styles}
                // onClickShift={request => openRequestSwapDialog(request, type)}
                // handleDailyClick={() => this.handleLink(new Date(data.schedule.schedule_date + 'T00:00:00'))}
                showPostShiftButton={false}
                flow="requestor"
              />
            </div>
          </Grid>
        </Grid>
      </Element>
    )
  }

  const renderFilterOptions = () => {
    return (
      <Grid container>
        <Grid item xs={12} md={12}>
          <div style={styles.filterOptionContainer}>
            <FilterOptions
              ownShift={currentShift}
              searchClicked={(searchOptions) => searchClicked(searchOptions)}
              expand={expand}
              toggleExpand={(val) => toggleExpand(val)}
            />
          </div>
        </Grid>
      </Grid>
    )
  }

  const renderMsgScreen = () => {
    return message
  }

  const renderSwapRequests = (incrementData) => {
    return (
      <>
        {shiftData.team_members_schedules.slice(0, incrementData).map((request, index) => (
          <div key={index}>
            {(() => {
              if (incrementData === index + 1 || shiftData.team_members_schedules.length === index + 1) {
                return (
                  <div
                    ref={lastElementRef}
                    style={styles.requestContainer}
                    data-cy={`shift-results-card-${index}`}
                    key={index}
                  >
                    <DisplaySegmentShiftSwap
                      shift={request}
                      onClickShift={() => openRequestSwapDialog(request)}
                      styles={styles}
                      key={index}
                    />
                  </div>
                )
              } else {
                return (
                  <div style={styles.requestContainer} data-cy={`shift-results-card-${index}`} key={index}>
                    <DisplaySegmentShiftSwap
                      shift={request}
                      onClickShift={() => openRequestSwapDialog(request)}
                      styles={styles}
                      key={index}
                    />
                  </div>
                )
              }
            })()}
          </div>
        ))}
      </>
    )
  }

  const loadMore = () => {
    const incrementData = visible + 50
    setVisible(incrementData)
    setLoadMoreBtnVisible(false)

    if (incrementData > shiftData.team_members_schedules.length) {
      setLoadMoreBtnVisible(false)
      setHasMore(false)
    } else {
      renderSwapRequests(incrementData)
      setHasMore(true)
    }
  }

  return (
    <React.Fragment>
      <HeaderTitle title={PAGE_TITLE} />
      {screenAccess.includes(SCREEN_NAME_SWAP_SHIFT) && renderComponent()}
      <>
        {loadMoreBtnVisible && (
          <button onClick={loadMore} type="button" style={styles.loadMore}>
            load more shifts
            <i style={styles.arrow} />
          </button>
        )}
      </>
    </React.Fragment>
  )
}

export default SearchSwapShifts
